/* colors */
$color-main: #fe0000;
$color-main-lighter: #ffffff;
$color-main-border: #f8515b;
$color-second-border-lighter: #f2ecec;
$color-border: #e2e3e6;
$color-shadow: #ddddddaa;
$color-dark-text: #000000;
$color-dark-low-opacity: #00000033;
$color-light: #FFFFFF;
$color-third: #464e5f;
$color-third-light: #bab8b8;
$color-highlight-text: #6A6A6A;
$color-highlight-bg: #F8F8F8;
$color-scrollbar: #b30000;
$color-placeholder: #a5a5a5;
$color-details-name: #b5b5c3;
$color-filter-background: #2b2d2e;
$color-title: #333;
$color-link: #0B1CF4;

$border-radius-main: 12px;
$border-radius-small: 6px;

/* responsiveness */
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-infinite: 10000px;

@mixin breakpoint($min, $max) {
    @media only screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin mobileBreakpoint {
    @include breakpoint($breakpoint-xs, $breakpoint-sm) {
        @content;
    }
}

@mixin tabletBreakpoint {
    @include breakpoint($breakpoint-sm, $breakpoint-lg) {
        @content;
    }
}

@mixin desktopBreakpoint {
    @include breakpoint($breakpoint-lg, $breakpoint-infinite) {
        @content;
    }
}

/* shadows */
$shadow-small: 0px 0px 4px 0px $color-shadow;
$shadow-regular: 0px 0px 4px 2px $color-shadow;
$shadow-big: 0px 0px 4px 5px $color-shadow;
